.header-wrapper {
  position: relative;
  padding: 0;
  height: 64px;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);
}

.header-collapsed {
  padding: 22px 24px;
  height: 64px;
  cursor: pointer;
  transition: all .3s,padding 0s;
  font-size: 20px;
}

.header-collapsed:hover, .header-dropdown-link:hover {
  background-color: rgba(0, 0, 0, .025);
}

.header-user-info {
  float: right;
  margin-right: 20px;
}

.header-dropdown-link {
  display: inline-block;
  padding: 0 12px;
  height: 100%;
  text-decoration: none;
  color: rgba(0, 0, 0, .65);
  transition: all .3s;
  cursor: pointer;
  font-size: 14px;
}

.header-dropdown-link i:first-child {
  font-size: 20px !important;
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-left: 5px;
}