.content-wrapper {
  min-height: 100vh !important;
}

.header {
  padding: 0 !important;
  width: 100%;
}

.main-content {
  height: calc(100% - 64px);
  overflow: auto;

  .content {
    padding-top: 0;
    // margin: 18px;
    background: #fff;
    border: 1px solid #dcdee2;
    border-color: #e8eaec;
    background: #fff;
    border-radius: 4px;
    font-size: 12px;
    position: relative;
    transition: all 0.2s ease-in-out;
    padding: 15px;
    // min-height: calc(100% - 68px);
    min-height: calc(100% - 68px + 36px);
    box-sizing: content-box;
    
    &:hover {
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
      border-color: #eee;
    }
  }
}

:global(.ant-layout){
  height: 100%;
}