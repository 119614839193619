.loading-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  background: rgba(0, 0, 0, 0.7);
}

.loading-content {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -100px;
  margin-top: -10px;
  width: 200px;
  height: 20px;
  z-index: 20000;
  text-align: center;
}