a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite,
code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4,
h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p,
pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead,
time, tr, tt, u, ul, var, video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  /* vertical-align: baseline; */
  /* vertical-align: middle; */
}

*, :after, :before {
  box-sizing: border-box;
}

body, html, #root {
  width: 100%;
  height: 100%;
}

html {
  font-family: sans-serif;
}

body {
  font-weight: 400;
  /* font-size: 1em; */
  font-size: 12px;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif;
}

a {
  color: #1890ff;
  background-color: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  -webkit-transition: color .3s;
  transition: color .3s;
  -webkit-text-decoration-skip: objects;
}

a:hover, a:focus {
  text-decoration: none;
}

img {
  vertical-align: middle;
  border-style: none;
  box-sizing: content-box;
  max-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  color: inherit;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 16px;
  margin-top: 1.5em;
}

h2 {
  font-size: 24px;
}

ol, ul {
  margin-bottom: 1em;
  margin-top: 0;
  padding-left: 2em;
}

.ant-dropdown-menu-item {
  min-width: 160px;
  width: 120px;
}

.ant-dropdown-placement-bottomRight {
  width: 120px;
  min-width: 120px!important;
}

.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th {
  padding: 8px;
}


.ant-table-fixed {
  table-layout: fixed;
}

.ant-table-body {
  overflow-x: auto!important;
}

.ant-table-tbody > tr > td {
  word-wrap: break-word;
  word-break: break-all;
}

.has-error .ant-form-explain {
  margin-left: 0!important;
}