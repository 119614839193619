.loading_loading-wrapper__7Dgcj {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  background: rgba(0, 0, 0, 0.7);
}

.loading_loading-content__266YN {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -100px;
  margin-top: -10px;
  width: 200px;
  height: 20px;
  z-index: 20000;
  text-align: center;
}
.login_login__1nTG4 {
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 600px;
  background: #F7F7F7; }

.login_header__18Jsv {
  position: fixed;
  width: 100%;
  min-height: 50px;
  padding: 8px 0;
  color: #fff;
  background: #20232a; }

.login_header-wrapper__1rBJO {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 20px; }

.login_header-wrapper__1rBJO header {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  text-align: left; }

.login_header-wrapper__1rBJO header a {
  display: flex;
  height: 34px;
  align-items: center;
  border: 0;
  color: #fff;
  flex-flow: row nowrap; }

.login_header-wrapper__1rBJO header img {
  height: 100%;
  margin-right: 10px; }

.login_header-wrapper__1rBJO header h2 {
  display: block;
  position: relative;
  line-height: 18px;
  margin: 0;
  font-size: 1.25em;
  white-space: nowrap; }

.login_nav-wrapper__bxUw- {
  position: relative;
  height: 34px;
  margin-left: auto; }

.login_nav-wrapper__bxUw- nav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
  box-sizing: border-box; }

@media only screen and (min-width: 1024px) {
  .login_nav-wrapper__bxUw- nav {
    position: relative;
    right: auto;
    top: auto;
    width: auto;
    height: auto;
    background: none; } }

.login_nav-wrapper__bxUw- nav ul {
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  margin-top: 50px;
  padding: 0;
  width: 100%;
  background: #61dafb;
  box-sizing: border-box;
  color: #fff; }

@media only screen and (min-width: 1024px) {
  .login_nav-wrapper__bxUw- nav ul {
    display: flex;
    flex-flow: row nowrap;
    margin: 0;
    padding: 0;
    width: auto;
    background: none; } }

.login_nav-wrapper__bxUw- nav ul li {
  flex: 1 1 auto;
  margin: 0;
  text-align: center;
  white-space: nowrap; }

.login_nav-wrapper__bxUw- nav ul li a {
  display: flex;
  margin: 0;
  padding: 10px;
  align-items: center;
  box-sizing: border-box;
  color: inherit;
  font-size: .9em;
  height: 50px;
  justify-content: center;
  -webkit-transition: background-color .3s;
  transition: background-color .3s; }

.login_nav-wrapper__bxUw- nav ul li a:hover {
  color: #ffffff; }

@media only screen and (min-width: 1024px) {
  .login_nav-wrapper__bxUw- nav ul li a {
    display: flex;
    margin: 0;
    padding: 6px 10px;
    height: 32px;
    line-height: 1.2em;
    border: 0;
    color: rgba(255, 255, 255, 0.8);
    font-size: 1em;
    font-weight: 300; } }

.login_login-title__X2gn3 {
  position: relative;
  top: 116px;
  text-align: center;
  margin-bottom: 30px;
  font-family: serif;
  font-size: 24px;
  color: #41464b;
  letter-spacing: 2px;
  font-weight: 700; }

.login_content__1IwaS {
  width: 520px;
  height: auto;
  margin: 0 auto;
  padding: 60px 90px;
  position: relative;
  top: 116px;
  display: flex;
  justify-content: center;
  box-shadow: 0 2px 3px 0 rgba(213, 213, 213, 0.7);
  box-sizing: border-box;
  background-color: #fff; }

.login_login-form__1YnJV {
  width: 100%; }
  .login_login-form__1YnJV .login_input-tips__2uGcP {
    display: block;
    margin: 8px 0;
    font-size: 12px;
    line-height: 1;
    color: #a5a5a5; }
  .login_login-form__1YnJV .login_login-form-forgot__psShX {
    float: right; }
  .login_login-form__1YnJV .login_login-form-button__v_Xd_ {
    width: 100%;
    height: 40px;
    margin-top: 20px;
    font-size: 14px;
    box-sizing: border-box;
    display: inline-block;
    border-radius: 2px;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    -webkit-transition: opacity 0.3s,border-color 0.3s;
    transition: opacity 0.3s,border-color 0.3s;
    color: #fff;
    border-color: #41464b;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#41464b), to(#2c3033));
    background-image: linear-gradient(#41464b, #2c3033);
    font-weight: bold;
    min-width: 100px;
    line-height: 40px;
    padding: 0 15px; }
    .login_login-form__1YnJV .login_login-form-button__v_Xd_:hover {
      background-color: #363b3e; }
    .login_login-form__1YnJV .login_login-form-button__v_Xd_:hover {
      background-image: none; }
    .login_login-form__1YnJV .login_login-form-button__v_Xd_:active {
      opacity: 0.9; }
    .login_login-form__1YnJV .login_login-form-button__v_Xd_:focus {
      outline: none; }

.login > .ant-form > .ant-form-item {
  height: 64px;
  margin-bottom: 0; }

.login > .ant-form-item-children > .ant-input {
  border-radius: 2px;
  background: #fff;
  box-shadow: inset 0 1px 3px 0 #e5e5e5;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
  width: 100%;
  box-sizing: border-box;
  color: #41464b;
  font-size: 14px;
  background: transparent;
  vertical-align: top; }

.login_footer__f2rFu {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 14px;
  position: absolute;
  bottom: 15px; }
  .login_footer__f2rFu a {
    color: #515a6e; }
    .login_footer__f2rFu a:hover {
      color: #57a3f3; }

@media screen and (max-width: 768px) {
  .login_login__1nTG4 .login_content__1IwaS {
    width: inherit;
    padding: 20px 30px; } }

.header_header-wrapper__Mxd6c {
  position: relative;
  padding: 0;
  height: 64px;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);
}

.header_header-collapsed__QzJYS {
  padding: 22px 24px;
  height: 64px;
  cursor: pointer;
  -webkit-transition: all .3s,padding 0s;
  transition: all .3s,padding 0s;
  font-size: 20px;
}

.header_header-collapsed__QzJYS:hover, .header_header-dropdown-link__udNiU:hover {
  background-color: rgba(0, 0, 0, .025);
}

.header_header-user-info__TqY1n {
  float: right;
  margin-right: 20px;
}

.header_header-dropdown-link__udNiU {
  display: inline-block;
  padding: 0 12px;
  height: 100%;
  text-decoration: none;
  color: rgba(0, 0, 0, .65);
  -webkit-transition: all .3s;
  transition: all .3s;
  cursor: pointer;
  font-size: 14px;
}

.header_header-dropdown-link__udNiU i:first-child {
  font-size: 20px !important;
}

.header_avatar__1YsE7 {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-left: 5px;
}
.sidebar_logo__1sTym {
  position: relative;
  padding-left: 14px;
  height: 64px;
  line-height: 64px;
  -webkit-transition: all .3s;
  transition: all .3s;
  background: #002140;
  overflow: hidden;
}

.sidebar_logo__1sTym img {
  display: inline-block;
  height: 36px;
}

.sidebar_logo__1sTym h1 {
  display: inline-block;
  margin: 0;
  color: #fff;
  vertical-align: middle;
  font-size: 20px;
  font-family: Avenir,Helvetica Neue,Arial,Helvetica,sans-serif;
  font-weight: 600;
}
.loader_loader-wrapper__2oeIY {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 30px;
}

.loader_loader-content__2nvG6 {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -20px;
}
.home_content-wrapper__2Z9pb {
  min-height: 100vh !important; }

.home_header__3WJ2C {
  padding: 0 !important;
  width: 100%; }

.home_main-content__30XLI {
  height: calc(100% - 64px);
  overflow: auto; }
  .home_main-content__30XLI .home_content___lk_H {
    padding-top: 0;
    background: #fff;
    border: 1px solid #dcdee2;
    border-color: #e8eaec;
    background: #fff;
    border-radius: 4px;
    font-size: 12px;
    position: relative;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    padding: 15px;
    min-height: calc(100% - 68px + 36px);
    box-sizing: content-box; }
    .home_main-content__30XLI .home_content___lk_H:hover {
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
      border-color: #eee; }

.ant-layout {
  height: 100%; }

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite,
code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4,
h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p,
pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead,
time, tr, tt, u, ul, var, video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  /* vertical-align: baseline; */
  /* vertical-align: middle; */
}

*, :after, :before {
  box-sizing: border-box;
}

body, html, #root {
  width: 100%;
  height: 100%;
}

html {
  font-family: sans-serif;
}

body {
  font-weight: 400;
  /* font-size: 1em; */
  font-size: 12px;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif;
}

a {
  color: #1890ff;
  background-color: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  -webkit-transition: color .3s;
  transition: color .3s;
  -webkit-text-decoration-skip: objects;
}

a:hover, a:focus {
  text-decoration: none;
}

img {
  vertical-align: middle;
  border-style: none;
  box-sizing: content-box;
  max-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  color: inherit;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 16px;
  margin-top: 1.5em;
}

h2 {
  font-size: 24px;
}

ol, ul {
  margin-bottom: 1em;
  margin-top: 0;
  padding-left: 2em;
}

.ant-dropdown-menu-item {
  min-width: 160px;
  width: 120px;
}

.ant-dropdown-placement-bottomRight {
  width: 120px;
  min-width: 120px!important;
}

.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th {
  padding: 8px;
}


.ant-table-fixed {
  table-layout: fixed;
}

.ant-table-body {
  overflow-x: auto!important;
}

.ant-table-tbody > tr > td {
  word-wrap: break-word;
  word-break: break-all;
}

.has-error .ant-form-explain {
  margin-left: 0!important;
}
.ant-table-pagination.ant-pagination {
  margin-bottom: 0;
}
form .ant-form-item-label label {
  color: #515a6e; }

form.ant-form label {
  font-size: 12px; }

